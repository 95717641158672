<template>
    <div>
        <div v-if="progressTotal" class="row">
            <div class="col-md-4 lexprogress-text-wrapper">
                <h2 :style="{'cursor': enableGotoProgress?'pointer':'default'}" v-on:click="gotoLexProgress">
                    {{ progressTotal.FinishedTMCountKernel }}<span>/{{progressTotal.FinishedTMCountAll}} {{$t('element_lexProgress.episode')}}</span>
                </h2>
                <p>{{$t('element_lexProgress.situationVideoProgress')}}</p>
            </div>
            <div class="col-md-4 lexprogress-text-wrapper">
                <h2 :style="{'cursor': enableGotoProgress?'pointer':'default'}" v-on:click="gotoLexProgress">
                    {{ progressTotal.TranslatorCount}}<span>/{{progressTotal.TranslatorCountHistory}} {{$t('element_lexProgress.people')}}</span>
                </h2>
                <p>{{$t('element_lexProgress.communityNop')}}</p>
            </div>
            <div class="col-md-4 lexprogress-text-wrapper">
                <h2 :style="{'cursor': enableGotoProgress?'pointer':'default'}" v-on:click="gotoLexProgress">

                    {{ Number.parseFloat(progressTotal.FinishedRateKernel * 100).toFixed(1) }}<span>/{{Number.parseFloat(progressTotal.FinishedRateAll * 100).toFixed(1)}} %</span>

                </h2>
                <p>{{$t('element_lexProgress.achievementRate')}}</p>
            </div>
            <div class="col-md-6 lexprogress-text-wrapper">
                <h2 :style="{'cursor': enableGotoProgress?'pointer':'default'}" v-on:click="gotoLexProgress">
                    {{ progressTotal.TMCCountKernel }}<span>/{{ progressTotal.TMCCountTM }} 句</span>
                </h2>
                <p>{{$t('element_lexProgress.achievementRate')}}</p>
            </div>
            <div class="col-md-6 lexprogress-text-wrapper">
                <h2 :style="{'cursor': enableGotoProgress?'pointer':'default'}" v-on:click="gotoLexProgress">
                    {{ progressTotal.TMLCountKernel }}<span>/{{ progressTotal.TMLCountTM }} 條</span>
                </h2>
                <p>{{$t('element_lexProgress.translationSentence')}}</p>
            </div>
        </div>

    </div>
</template>

<script>
    import { ghelper } from '@/lib/ghelper';
    import Vue from 'vue'

    import VModal from 'vue-js-modal';
    import 'vue-js-modal/dist/styles.css'
    Vue.use(VModal, {
        dialog: true
    });

    import Loading from 'vue-loading-overlay';
    Vue.use(Loading);

    export default {
        name: 'App',
        props: {
            enableGotoProgress: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                /**
                 * 計劃總進度的資料。格式如下：
                 * {
                 *     "TMCount": "51",            // 情景影片總進度
                 *     "FinishRate": "0.042500",   // 總達成率
                 *     "TranslatorCount": "17",    // 翻譯社群總參與人數
                 *     "TMCCount": "1875",         // 總翻譯例句
                 *     "TMLCount": "794"           // 總詞彙數
                 * }
                 */
                progressTotal: null,

            };
        },
        methods: {
            alert: function (msg) {
                this.$modal.show('dialog', {
                    title: '請注意',
                    text: msg,
                    buttons: [{
                        title: '關閉'
                    }]
                });
            },
            get1200LexProgressTotal: function () {
                var loader = Vue.$loading.show();
                this.axios.post('LeaderBoard/Get1200LexProgressTotal', {
                        l: 'vi-VN',
                    })
                    .then(res => {
                        var data = res.data;
                        if (data.success) {
                            this.progressTotal = data.data;
                        } else if (data.success == false) {
                            this.alert(data.message);
                        }
                        loader.hide();
                    })
                    .catch(error => {
                        if (error.response) {
                            // 當狀態碼不在 validateStatus 設定的範圍時進入, 401 感覺會跑來這。待確認
                            // 有 data / status / headers 參數可用
                            console.log(error.response.status)
                        } else if (error.request) {
                            // 發送請求，但沒有接到回應
                            // error.request is an instance of XMLHttpRequest in the browser
                            // and an instance of http.ClientRequest in node.js
                            console.log(error.request)
                        } else {
                            // 在設定 request 時出錯會進入此
                            console.log('Error', error.message)
                        }
                        this.alert('無法取得總進度統計資料。發生不知名的錯誤');
                        loader.hide();
                    });
            },
            gotoLexProgress: function () {
                if (this.enableGotoProgress) {
                    this.$gtag.event('ViewProgress', {
                        'event_category': 'iyuyan',
                        'event_label': 'homepage_progress',
                        'value': 1
                    });

                    var url = ghelper.getActionUrl('LexProgress', 'LeaderBoard');
                    location.href = url;
                }
            },
            initData: function () {
                this.get1200LexProgressTotal();
            },
        }, // end of methods
        computed: {

        },
        mounted: function () {
            this.initData();
        },
        watch: {},
    }
</script>

<style>
    .lexprogress-text-wrapper {
        text-align: center;
    }

    .lexprogress-text-wrapper h2 {
        font-size: 6.5rem;
        font-weight: 800;
        color: #456CEB;
    }

    .lexprogress-text-wrapper span {
        font-size: 2.5rem;
        color: #456CEB;
    }

    .lexprogress-text-wrapper p {
        font-size: 1.2rem;
        font-weight: 500;
    }
</style>