<template lang="html">
    <div>
        <div class="banner-wrapper">
            <div class="banner-title-wrapper">
                <div class="banner-title">
                    {{$t('element_banner.yourVietnameseClass')}}
                    <!-- 您的越南語<br/>
                    線上影音教室 -->
                </div>
                <div class="banner-red-line"></div>
                <div class="banner-second-title mt-3">{{$t('element_banner.secondTitle')}}</div>
                <div class="banner-search-input-wrapper">
                    <input v-on:focus="searchBoxStyler(true)" v-on:blur="searchBoxStyler(false)" :class="{ 'search-focus-stop': searchfocus }" class="banner-search" type="search" name="q" :placeholder="$t('element_banner.searchVocabulary')" aria-label="Search through site content"
                           v-model="searchLex">
                    <!-- <select class="banner-search-select">
                        <option>搜尋文本</option>
                        <option>搜尋例句</option>
                        <option>搜尋片名</option>
                    </select> -->
                    <select class="header-search-select" v-model="searchLang">
                        <option value="zh-Hant">繁中</option>
                        <option value="vi-VN">VN</option>
                    </select>
                    <img alt="" class="search-button-red" src="@/assets/image/search-button-red.svg" v-on:click="gotoLexCorpus">
                </div>
            </div>
            <img alt="" class="w-100 banner-home" src="@/assets/image/banner-home.png">
        </div>
        <div class="container pt-5">
            <div class="row  situation-wrapper">
                <div class="col-md-7">
                    <img alt="" class="w-100" src="@/assets/image/diagram-beach.png">
                </div>
                <div class="col-md-5">
                    <h4>{{$t('element_content_analytic.associateVideo')}}</h4>
                    <p>{{$t('element_content_analytic.avIntroduce')}}</p>

                    <button class="btn btn-second" onclick="location.href='https://bit.ly/34dZB4D'">{{$t('element_content_analytic.situationVideo')}}</button>

                </div>
            </div>
            <h2 class="text-center mt-5">{{$t('element_content_analytic.howWeHelpYou')}}</h2>
            <div class="row about-ai-wrap">
                <div class="col-md-6 p-2">
                    <div class="d-flex-center h-100 flex-wrap">
                        <div class="inner-wrap-padding font-m">
                            <h4 class="second-title">{{$t('element_content_analytic.aiSelfStudy')}}</h4>
                            <ul class="mt-3">
                                <li>
                                    <p class="list-title">{{$t('element_content_analytic.aiAnalyze')}}</p>
                                    <p>{{$t('element_content_analytic.likeYourAssistant')}}</p>
                                </li>
                                <li>
                                    <p class="list-title">{{$t('element_content_analytic.relatedWordA')}}</p>
                                    <p style="letter-spacing: -1px;">{{$t('element_content_analytic.allRelated')}}</p>
                                <li>
                                    <p class="list-title">{{$t('element_content_analytic.radicalPrefixA')}}</p>
                                    <p>{{$t('element_content_analytic.moreVocabulary')}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 p-2">
                    <div class="d-flex-center h-100 flex-wrap">
                        <div>
                            <img alt="" class="w-100 content-img" src="@/assets/image/content-img-ai.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row p-2">
                <div class="col-md-6 p-2 hide-m">
                    <div class="d-flex-center h-100 flex-wrap">
                        <div>
                            <img alt="" class="w-100 content-img" src="@/assets/image/content-img-video.png">
                        </div>
                    </div>
                </div>
                <div class="col-md-6 p-2">
                    <div class="d-flex-center h-100 flex-wrap font-m">
                        <div class="inner-wrap-padding">
                            <h4 class="second-title">{{$t('element_content_analytic.lookVideoStudy')}}</h4>
                            <p>{{$t('element_content_analytic.videoIsGoodLaugurage')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 p-2 hide-d">
                    <div class="d-flex-center h-100 flex-wrap">
                        <div>
                            <img alt="" class="w-100 content-img" src="@/assets/image/content-img-video.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row p-2">
                <div class="col-md-6 p-2">
                    <div class="d-flex-center h-100 flex-wrap font-m">
                        <div class="inner-wrap-padding">
                            <h4 class="second-title">{{$t('element_content_analytic.corpusSearch')}}</h4>
                            <p>{{$t('element_content_analytic.exceedMoreCaptions')}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 p-2">
                    <div class="d-flex-center h-100 flex-wrap">
                        <div>
                            <img alt="" class="w-100 content-img" src="@/assets/image/content-img-singleWord.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h3 class="text-center mt-5">{{$t('element_content_analytic.teacherAndCommunity')}}</h3>
        <div class="containter-fulid teacher-wrapper">
            <div class="container">
                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="@/assets/image/banner-teacher-2.png" class="d-block w-100" alt="...">
                        </div>
                        <div class="carousel-item">
                            <img src="@/assets/image/banner-teacher-3.png" class="d-block w-100" alt="...">
                        </div>
                        <div class="carousel-item">
                            <img src="@/assets/image/banner-teacher-4.png" class="d-block w-100" alt="...">
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="container p-5 mt-3">
            <div class="row">
                <div class="col-md-7 position-relative">
                    <img src="@/assets/image/social-meaasge-img.png" class="w-100" alt="...">
                    <div class="heart-p-1"><img class="heart" src="@/assets/image/social-heart.png" alt="..."></div>
                    <div class="heart-p-2"><img class="heart-1" src="@/assets/image/social-heart.png" alt="..."></div>
                    <div class="heart-p-3"><img class="heart-2" src="@/assets/image/social-heart.png" alt="..."></div>
                    <div class="heart-p-4"><img class="heart-3" src="@/assets/image/social-heart.png" alt="..."></div>
                    <div class="good-p-1"><img class="good" src="@/assets/image/social-good.png" alt="..."></div>
                    <div class="good-p-2"><img class="good-1" src="@/assets/image/social-good.png" alt="..."></div>
                    <div class="good-p-3"><img class="good-2" src="@/assets/image/social-good.png" alt="..."></div>
                    <div class="good-p-4"><img class="good-3" src="@/assets/image/social-good.png" alt="..."></div>
                    <div class="happyFace-p-1"><img class="happyFace" src="@/assets/image/social-happyFace.png" alt="..."></div>
                    <div class="happyFace-p-2"><img class="happyFace-1" src="@/assets/image/social-happyFace.png" alt="..."></div>
                    <div class="happyFace-p-3"><img class="happyFace-2" src="@/assets/image/social-happyFace.png" alt="..."></div>
                </div>
                <div class="col-md-5">
                    <div class="d-flex-center h-100">
                        <div>
                            <div class="social-title" v-html="$t('element_content_analytic.newLangurageChinese')"></div>
                            <p class="p-3">{{$t('element_content_analytic.needfriend')}}</p>
                            <div class="w-100 d-flex-center">
                                <button @click="scrollToFBGroup" class="btn btn-second">{{$t('element_content_analytic.joinNow')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mb-5 mt-5">
            <video-progress :enable-goto-progress="true"></video-progress>
        </div>
        <div class="container-fulid">

            <div class="together-wrapper" @mouseover="tranformmask =!tranformmask">
                <!-- <div @mouseout="tranformmask =!tranformmask" class="together-mask-box"
                    :class="{ 'together-mask-1' : tranformmask }"> -->
                <!-- <div class="container row">
                    <div class="col-md-9 d-flex-center">
                        <div v-show="currentStep == 1" class="text-white">
                            {{$t('element_content_analytic.stepTextOne')}}
                        </div>
                        <div v-show="currentStep == 2" class="text-white">
                            {{$t('element_content_analytic.stepTextTwo')}}
                        </div>
                        <div v-show="currentStep == 3" class="text-white">
                            {{$t('element_content_analytic.stepTextThree_1')}}
                            <br/><br/>
                            {{$t('element_content_analytic.stepTextThree_2')}} <div class="text-white" style="text-decoration:underline;" v-on:click="more1200Lex">{{$t('element_content_analytic.stepTextThree_3')}}</div>
                            <br/>
                            {{$t('element_content_analytic.stepTextThree_4')}}
                        </div>
                        <div>
                            <button v-show="currentStep == 2" @click.prevent="prevStep"
                                class="btn btn-second-board mr-2">上一步</button>
                            <button :class="{ 'button-hidden' : currentStep == 3 }" @click.prevent="nextStep"
                                class="btn btn-second">下一步</button>
                            <button v-show="currentStep == 3" @click.prevent="prevStep"
                                class="btn btn-second-board mr-2">上一步</button>
                            <button v-show="currentStep == 3" @click.prevent="prevStep"
                                class="btn btn-second" v-on:click="contactUs('homepage_ad')">{{$t('element_content_analytic.contactUs')}}</button>

                        </div>
                    </div>
                    <div class="col-md-3">
                        <img v-show="currentStep == 1" src="@/assets/image/banner-together-sample-1.png"
                            class="w-100 hide-mobile" alt="...">
                        <img v-show="currentStep == 2" src="@/assets/image/banner-together-sample-2.png"
                            class="w-100 hide-mobile" alt="...">
                        <img v-show="currentStep == 3" src="@/assets/image/banner-together-sample-3.png"
                            class="w-100 hide-mobile" alt="...">
                    </div>
                </div> -->
                <!-- </div> -->
                <div class="joinUs-wrap">
                    <img src="@/assets/image/banner-together-n.jpg" class="d-block w-100 mt-5 together-img hide-mobile"
                         alt="...">
                    <div class="joinUs-btn-wrap">
                        <button onclick="location.href='/LeaderBoard/LexProgress'" class="btn btn-second">{{$t('element_content_analytic.projectAction')}}</button>
                        <!-- 2nd action, 先遮蔽
                        <button data-toggle="modal" data-target="#signUpModal" class="btn btn-line-board">{{$t('element_content_analytic.sponsor')}}</button>
                        -->
                    </div>
                    <img src="@/assets/image/banner-together-mobile-n.jpg"
                         class="d-block w-100 mt-5 together-img hide-desktop" alt="...">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ghelper, getCookie } from "@/lib/ghelper";
    import VideoProgress from "@/components/Elements/VideoProgress.vue";

    export default {
        data: function () {
            return {
                tranformmask: true,
                currentStep: 1,

                searchLex: null,
                searchLang: null,

                flash: false,
                searchfocus: false
            };
        },
        components: {
            VideoProgress
        },
        methods: {
            alert: function (msg) {
                this.$modal.show("dialog", {
                    title: "請注意",
                    text: msg,
                    buttons: [
                        {
                            title: "關閉"
                        }
                    ]
                });
            },
            scrollToFBGroup: function () {
                var element = document.querySelector("#fb-group");

                // smooth scroll to element and align it at the bottom
                element.scrollIntoView({ behavior: 'smooth', block: 'end' });
            },
            setSearchLang: function (lang) {
                document.cookie = "serach_lang = " + lang + "; path=/";
                this.searchLang = lang;
            },
            gotoLexCorpus: function () {
                var trimmedLex = this.searchLex ? this.searchLex.trim() : null;
                if (trimmedLex) {
                    this.$gtag.event("LexCorpus", {
                        event_category: "iyuyan",
                        event_label: "homepage",
                        value: 3
                    });

                    let url = null;

                    if (this.searchLang == "vi-VN") {
                        url =
                            ghelper.getActionUrl("Corpus", "Lex") +
                            "/vi-VN/zh-Hant/" +
                            trimmedLex;
                    } else {
                        url =
                            ghelper.getActionUrl("Corpus", "Lex") +
                            "/zh-Hant/vi-VN/" +
                            trimmedLex;
                    }

                    location.href = url;
                } else {
                    this.alert("請輸入要搜尋的詞彙。");
                }
            },
            nextStep: function () {
                this.currentStep++;
            },
            prevStep: function () {
                this.currentStep--;
            },
            contactUs: function (lbl) {
                this.$gtag.event("Contact", {
                    event_category: "iyuyan",
                    event_label: lbl,
                    value: 100
                });

                window.open("https://m.me/iyuewen", "_blank");
            },
            more1200Lex: function () {
                this.$gtag.event("AdMore", {
                    event_category: "iyuyan",
                    event_label: "homepage",
                    value: 10
                });

                window.open("https://bit.ly/2XSMd2s", "_blank");
            },
            moreSC: function (lbl) {
                this.$gtag.event("MoreSC", {
                    event_category: "iyuyan",
                    event_label: lbl,
                    value: 10
                });

                location.href = ghelper.getActionUrl("About", "Speaking");
            },
            searchBoxStyler: function (event) {
                // console.log(event)
                if (event == true) {
                    this.searchfocus = true;
                } else {
                    this.searchfocus = false;
                }
            }
        },
        mounted() {
            let searchLang = getCookie("serach_lang");

            if (searchLang) {
                this.searchLang = searchLang;
            } else {
                this.searchLang = "vi-VN";
            }
        },
        watch: {
            searchLang: function (newVal) {
                this.setSearchLang(newVal);
            }
        }
    };
</script>


<style>
    .banner-wrapper {
        position: relative;
    }

    .banner-search {
        border-radius: 50px;
        border: none;
        width: 100%;
        padding: 14px 70px 14px 20px;
        animation-name: oxxo;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        background-color: white;
    }

    .search-focus-stop {
        animation-name: none;
        /* box-shadow: none;  */
    }

        .search-focus-stop:focus {
            box-shadow: none !important;
        }

    .banner-search-select {
        position: absolute;
        border: none;
        right: 60px;
        top: 16px;
        border-left: 1px solid;
        padding-left: 10px;
    }

        .banner-search-select:focus {
            outline: none;
        }

    @keyframes oxxo {
        0% {
            box-shadow: 0 0 0 0rem #ff8f9ccf;
        }

        50% {
            box-shadow: 0 0 0 0.4rem #ff8f9ccf;
        }

        100% {
            box-shadow: 0 0 0 0rem #ff8f9ccf;
        }
    }

    .banner-search:focus {
        border-radius: 50px !important;
        border: none;
        outline: none;
        box-shadow: 0 0 0 0.25rem #456cebe3;
    }

    .banner-search-input-wrapper {
        position: relative;
    }

    .banner-title-wrapper {
        position: absolute;
        left: 5%;
        top: 10%;
        color: #fff;
        width: 400px;
        /* background-color: #456CEB; */
    }

    .banner-title {
        font-weight: 800;
        font-size: 4rem;
        position: relative;
        z-index: 9;
        text-shadow: 0.7px 0.7px 10px rgba(0, 0, 0, 0.475);
    }

    .banner-red-line {
        width: 100%;
        height: 30px;
        background-color: #ff6072;
        position: absolute;
        bottom: 100px;
        left: -6px;
    }

    .banner-second-title {
        font-weight: 400;
        font-size: 1.2rem;
        margin-top: -1px !important;
        margin-bottom: 11px;
        text-shadow: 0.7px 0.7px 10px rgba(0, 0, 0, 0.475);
    }

    .search-button-red {
        position: absolute;
        top: 6px;
        right: 10px;
        width: 40px;
        cursor: pointer;
    }

        .search-button-red:hover {
            opacity: 0.6;
        }

    .situation-wrapper button {
        margin: 0 auto;
        display: block;
    }

    .situation-wrapper h4 {
        margin-top: 21%;
    }

    .aboutus-box {
        overflow: hidden;
        position: relative;
        background-color: #f7f7f7;
        padding: 19px 20px 27px 20px;
        border-radius: 10px;
    }

    .aboutus-title {
        color: #e32f42;
    }

    .aboutus-img-icon {
        position: absolute;
        bottom: -15px;
        right: 26px;
    }

    .aboutus-second-box {
        background-color: #f7f7f7;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        display: block;
    }

    .aboutus-text-more {
        color: #456ceb !important;
    }

    .aboutus-box-img {
        width: 100%;
        height: 229px;
        overflow: hidden;
    }

    .aboutus-second-box:hover {
        opacity: 0.8;
    }

        .aboutus-second-box:hover img {
            transform: scale(1.2);
        }

    .teacher-wrapper {
        background-color: #f7f7f7;
        padding: 0px;
        margin-top: 19px;
    }

    .box {
        width: 200px;
        height: 200px;
        border: 1px solid;
        transition: 2s;
    }

    .together-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .together-mask-box {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        cursor: pointer;
        background-color: #000000d2;
        width: 100%;
        height: 36vh;
        position: absolute;
    }

    .together-img {
        height: 36vh;
    }

    .contact-image {
        width: 100%;
        padding: 0px 22%;
        cursor: pointer;
    }

    .together-mask-1 {
        opacity: 0;
    }

    .button-hidden {
        display: none;
    }

    /* new css */
    .second-title {
        color: #456ceb;
    }

    .about-ai-wrap ul {
        padding-left: 20px;
    }

        .about-ai-wrap ul li {
            list-style-type: square !important;
            color: #456ceb;
            font-size: 16px !important;
            line-height: 30px !important;
            margin-bottom: 5px;
        }

            .about-ai-wrap ul li p {
                color: #212529;
            }

    .list-title {
        font-size: 1.2rem;
        font-weight: 800;
        margin-bottom: -3px;
    }

    .list-title {
        font-size: 1.2rem;
        font-weight: 800;
    }

    .content-img {
        padding: 30px 12px;
    }

    .font-m p {
        font-size: 1.1rem;
    }

    .situation-wrapper p {
        font-size: 1.2rem;
    }



    @media screen and (max-width: 875px) {
        .banner-wrapper {
            width: 100%;
            height: 500px;
            background-image: url("~@/assets/image/banner-home.png");
            background-position: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }

            .banner-wrapper .banner-home {
                display: none;
            }

        .banner-title-wrapper {
            position: relative;
            left: 0%;
            top: 0%;
        }
    }

    @media screen and (min-width: 992px) {
        .inner-wrap-padding {
            padding: 0px 50px;
        }

        .hide-d {
            display: none;
        }
    }

    @media screen and (max-width: 992px) {
        .hide-m {
            display: none;
        }

        .second-title {
            margin-top: 40px;
        }

        .content-img {
            padding: 0px 12px;
        }
    }

    @media screen and (max-width: 420px) {
        .banner-title-wrapper {
            zoom: 0.9;
        }
    }

    @media screen and (max-width: 360px) {
        .banner-title-wrapper {
            zoom: 0.8;
        }
    }

    @media screen and (max-width: 768px) {
        .hide-mobile {
            display: none !important;
        }

        .together-mask-box {
            height: inherit;
        }

        .together-img {
            height: auto;
        }

        .situation-wrapper h4 {
            margin-top: 3%;
        }

        .contact-image {
            width: 100%;
            padding: 0px 6%;
        }
    }

    @media screen and (min-width: 768px) {
        .hide-desktop {
            display: none !important;
        }
    }

    /* css動畫 */

    .heart {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0px;
        top: 0px;
        animation-name: rightMove-1, bottomMove-1;
        animation-duration: 6s;
        animation-iteration-count: infinite;
    }

    .good {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0px;
        top: 0px;
        animation-name: rightMove-4, bottomMove-4;
        animation-duration: 4s;
        animation-iteration-count: infinite;
    }

    .good-1 {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0px;
        top: 0px;
        animation-name: rightMove-5, bottomMove-5;
        animation-duration: 4s;
        animation-iteration-count: infinite;
    }

    .good-2 {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0px;
        top: 0px;
        animation-name: rightMove-6, bottomMove-6;
        animation-duration: 4s;
        animation-iteration-count: infinite;
    }

    .good-3 {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0px;
        top: 0px;
        animation-name: rightMove-10, bottomMove-10;
        animation-duration: 4s;
        animation-iteration-count: infinite;
    }

    .good-p-1 {
        position: absolute;
        right: 230px;
        bottom: 0px;
    }

    .good-p-2 {
        position: absolute;
        right: 210px;
        bottom: 0px;
    }

    .good-p-3 {
        position: absolute;
        right: 260px;
        bottom: 0px;
    }

    .good-p-4 {
        position: absolute;
        right: 262px;
        bottom: 0px;
    }

    .heart-p-1 {
        position: absolute;
        right: 200px;
        bottom: 0px;
    }

    .heart-p-2 {
        position: absolute;
        right: 230px;
        bottom: 0px;
    }

    .heart-p-3 {
        position: absolute;
        right: 240px;
        bottom: 0px;
    }

    .heart-p-4 {
        position: absolute;
        right: 200px;
        bottom: 0px;
    }

    .heart-1 {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0px;
        top: 0px;
        animation-name: rightMove-2, bottomMove-2;
        animation-duration: 6s;
        animation-iteration-count: infinite;
    }

    .heart-2 {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0px;
        top: 0px;
        animation-name: rightMove-3, bottomMove-3;
        animation-duration: 4s;
        animation-iteration-count: infinite;
    }

    .heart-3 {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0px;
        top: 0px;
        animation-name: rightMove-11, bottomMove-11;
        animation-duration: 8s;
        animation-iteration-count: infinite;
    }

    .happyFace {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0px;
        top: 0px;
        animation-name: rightMove-7, bottomMove-7;
        animation-duration: 9s;
        animation-iteration-count: infinite;
    }

    .happyFace-p-1 {
        position: absolute;
        right: 220px;
        bottom: 10px;
    }

    .happyFace-1 {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0px;
        top: 0px;
        animation-name: rightMove-8, bottomMove-8;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }

    .happyFace-p-2 {
        position: absolute;
        right: 226px;
        bottom: 10px;
    }

    .happyFace-2 {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0px;
        top: 0px;
        animation-name: rightMove-9, bottomMove-9;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }

    .happyFace-p-3 {
        position: absolute;
        right: 235px;
        bottom: 10px;
    }

    .social-title {
        font-size: 3rem;
        text-align: center;
        font-weight: 600;
    }

    @keyframes rightMove-1 {
        50% {
            animation-timing-function: linear;
            left: -420px;
        }

        60% {
            width: 40px;
            height: 40px;
        }

        100% {
            opacity: 0;
            width: 20px;
            height: 20px;
        }
    }

    @keyframes bottomMove-1 {
        50% {
            animation-timing-function: linear;
            top: -450px;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes rightMove-2 {
        50% {
            animation-timing-function: linear;
            left: -100px;
        }

        60% {
            width: 40px;
            height: 40px;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes bottomMove-2 {
        50% {
            animation-timing-function: linear;
            top: -400px;
        }

        100% {
            opacity: 0;
            width: 20px;
            height: 20px;
        }
    }

    @keyframes rightMove-3 {
        50% {
            animation-timing-function: linear;
            left: 0px;
        }

        60% {
            width: 40px;
            height: 40px;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes bottomMove-3 {
        50% {
            animation-timing-function: linear;
            top: -200px;
        }

        100% {
            opacity: 0;
            width: 20px;
            height: 20px;
        }
    }

    @keyframes rightMove-4 {
        50% {
            animation-timing-function: linear;
            left: 40px;
        }

        60% {
            width: 40px;
            height: 40px;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes bottomMove-4 {
        50% {
            animation-timing-function: linear;
            top: -500px;
        }

        100% {
            opacity: 0;
            width: 20px;
            height: 20px;
        }
    }

    @keyframes rightMove-5 {
        50% {
            animation-timing-function: linear;
            left: -100px;
        }

        60% {
            width: 40px;
            height: 40px;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes bottomMove-5 {
        50% {
            animation-timing-function: linear;
            top: -250px;
        }

        100% {
            opacity: 0;
            width: 20px;
            height: 20px;
        }
    }

    @keyframes rightMove-6 {
        50% {
            animation-timing-function: linear;
            left: 200px;
        }

        60% {
            width: 40px;
            height: 40px;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes bottomMove-6 {
        50% {
            animation-timing-function: linear;
            top: -250px;
        }

        100% {
            opacity: 0;
            width: 20px;
            height: 20px;
        }
    }

    @keyframes rightMove-7 {
        50% {
            animation-timing-function: linear;
            left: 140px;
        }

        60% {
            width: 40px;
            height: 40px;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes bottomMove-7 {
        50% {
            animation-timing-function: linear;
            top: -250px;
        }

        100% {
            opacity: 0;
            width: 20px;
            height: 20px;
        }
    }

    @keyframes rightMove-8 {
        50% {
            animation-timing-function: linear;
            left: -140px;
        }

        60% {
            width: 40px;
            height: 40px;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes bottomMove-8 {
        50% {
            animation-timing-function: linear;
            top: -180px;
        }

        100% {
            opacity: 0;
            width: 20px;
            height: 20px;
        }
    }

    @keyframes rightMove-9 {
        50% {
            animation-timing-function: linear;
            left: 100px;
        }

        60% {
            width: 40px;
            height: 40px;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes bottomMove-9 {
        50% {
            animation-timing-function: linear;
            top: -300px;
        }

        100% {
            opacity: 0;
            width: 20px;
            height: 20px;
        }
    }

    @keyframes rightMove-10 {
        50% {
            animation-timing-function: linear;
            left: -100px;
        }

        60% {
            width: 40px;
            height: 40px;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes bottomMove-10 {
        50% {
            animation-timing-function: linear;
            top: -380px;
        }

        100% {
            opacity: 0;
            width: 20px;
            height: 20px;
        }
    }

    @keyframes rightMove-11 {
        50% {
            animation-timing-function: linear;
            left: 0px;
        }

        60% {
            width: 40px;
            height: 40px;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes bottomMove-11 {
        50% {
            animation-timing-function: linear;
            top: -280px;
        }

        100% {
            opacity: 0;
            width: 20px;
            height: 20px;
        }
    }

    .joinUs-wrap {
        position: relative;
    }

    .joinUs-btn-wrap {
        position: absolute;
        top: 29%;
        right: 5%;
    }

        .joinUs-btn-wrap .btn-line-board {
            width: 110px;
            margin-left: 13px;
        }

    @media screen and (max-width:992px) {
        .joinUs-btn-wrap {
            position: absolute;
            top: 62%;
            right: 5%;
        }
    }
</style>